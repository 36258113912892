export const CATALOG_TRACKER_VIEW_TYPE_DATA = {
  name: "competitive_retail_tracker",
  id: 9,
};

export const DOMAIN_TO_ACCESSOR_MAP = {
  "amazon.com": "amazonUs",
  "wayfair.com": "wayfairUs",
  "wells.ca": "wellsCa",
  "walmart.com": "walmartUs",
  "target.com": "targetUs",
  "overstock.com": "overstockUs",
  "ulta.com": "ultaUs",
  "sephora.com": "sephoraUs",
  "walmart.ca": "walmartCA",
  "realcanadiansuperstore.ca": "realCanadianSuperstoreCA",
  "voila.ca": "voilaCA",
  "metro.ca": "metroCA",
  "lazada.co.id": "lazadaID",
  "shopee.co.id": "shopeeID",
  "lazada.vn": "lazadaVN",
  "shopee.vn": "shopeeVN",
  "amazon.in": "amazonIN",
  "flipkart.com": "flipkartCom",
  "electrical.com": "ElectricalCom",
  "simplybreakers.com": "SimplyBreakersCom",
  "myfood4less.com": "MyFoodForLess",
  "falabella.com": "falabellaCom",
  "mercadolibre.cl": "mercadolibreCl",
};

export const CELL_VALUE_CLASSIFICATION = {
  single: 0,
  multiple: 1,
};

export const CATALOG_TRACKER_VALUE_TYPE = {
  number: 0,
  price_diff: 1,
  number_diff: 3,
  average: 4,
  oos: 5,
  index: 6,
  ratings_and_avg: 7,
  promo: 8,
};

export const DOMAINS_TO_COLUMNS_CONFIG = {
  "amazon.com": {
    id: 1,
    label: "Amazon US",
    accessor: DOMAIN_TO_ACCESSOR_MAP["amazon.com"],
    tooltip: "",
  },
  "wayfair.com": {
    id: 2,
    label: "Wayfair US",
    accessor: DOMAIN_TO_ACCESSOR_MAP["wayfair.com"],
    tooltip: "",
  },
  "wells.ca": {
    id: 3,
    label: "Wells Canada",
    accessor: DOMAIN_TO_ACCESSOR_MAP["wells.ca"],
    tooltip: "",
  },
  "walmart.com": {
    id: 4,
    label: "Walmart US",
    accessor: DOMAIN_TO_ACCESSOR_MAP["walmart.com"],
    tooltip: "",
  },
  "target.com": {
    id: 5,
    label: "Target US",
    accessor: DOMAIN_TO_ACCESSOR_MAP["target.com"],
    tooltip: "",
  },
  "overstock.com": {
    id: 6,
    label: "Overstock US",
    accessor: DOMAIN_TO_ACCESSOR_MAP["overstock.com"],
    tooltip: "",
  },
  "ulta.com": {
    id: 7,
    label: "Ulta US",
    accessor: DOMAIN_TO_ACCESSOR_MAP["ulta.com"],
    tooltip: "",
  },
  "sephora.com": {
    id: 8,
    label: "Sephora US",
    accessor: DOMAIN_TO_ACCESSOR_MAP["sephora.com"],
    tooltip: "",
  },
  "walmart.ca": {
    id: 9,
    label: "Walmart Canada",
    accessor: DOMAIN_TO_ACCESSOR_MAP["walmart.ca"],
    tooltip: "",
  },
  "realcanadiansuperstore.ca": {
    id: 10,
    label: "Real Canadian Superstore",
    accessor: DOMAIN_TO_ACCESSOR_MAP["realcanadiansuperstore.ca"],
    tooltip: "",
  },
  "voila.ca": {
    id: 11,
    label: "Voila Canada",
    accessor: DOMAIN_TO_ACCESSOR_MAP["voila.ca"],
    tooltip: "",
  },
  "metro.ca": {
    id: 12,
    label: "Metro Canada",
    accessor: DOMAIN_TO_ACCESSOR_MAP["metro.ca"],
    tooltip: "",
  },
  "lazada.co.id": {
    id: 13,
    label: "Lazada Indonesia",
    accessor: "lazadaID",
    tooltip: "",
  },
  "shopee.co.id": {
    id: 14,
    label: "Shopee Indonesia",
    accessor: "shopeeID",
    tooltip: "",
  },
  "lazada.vn": {
    id: 15,
    label: "Lazada Vietnam",
    accessor: "lazadaVN",
    tooltip: "",
  },
  "shopee.vn": {
    id: 16,
    label: "Shopee Vietnam",
    accessor: "shopeeVN",
    tooltip: "",
  },
  "amazon.in": {
    id: 16,
    label: "Amazon India",
    accessor: DOMAIN_TO_ACCESSOR_MAP["amazon.in"],
    tooltip: "",
  },
  "flipkart.com": {
    id: 16,
    label: "FlipKart",
    accessor: DOMAIN_TO_ACCESSOR_MAP["flipkart.com"],
    tooltip: "",
  },
  "electrical.com": {
    id: 16,
    label: "Electrical",
    accessor: DOMAIN_TO_ACCESSOR_MAP["electrical.com"],
    tooltip: "",
  },
  "simplybreakers.com": {
    id: 16,
    label: "SimplyBreakers",
    accessor: DOMAIN_TO_ACCESSOR_MAP["simplybreakers.com"],
    tooltip: "",
  },
  "myfood4less.com": {
    id: 16,
    label: "MyFood4Less",
    accessor: DOMAIN_TO_ACCESSOR_MAP["myfood4less.com"],
    tooltip: "",
  },
  "falabella.com": {
    id: 16,
    label: "Falabella",
    accessor: DOMAIN_TO_ACCESSOR_MAP["falabella.com"],
    tooltip: "",
  },
  "mercadolibre.cl": {
    id: 16,
    label: "MercadoLibre",
    accessor: DOMAIN_TO_ACCESSOR_MAP["mercadolibre.cl"],
    tooltip: "",
  },
};

export const INDEX_COLORS = [
  "#5771F4",
  "#D871D4",
  "#41CAC2",
  "#7FB7F5",
  "#3CC4FF",
  "#8F3F3F",
  "#B36B6B",
  "#E6A3A3",
  "#6B8FB3",
  "#A3C1E6",
  "#4DA361",
  "#8FD18F",
  "#D1F0D1",
  "#B38FB3",
  "#E6B3E6",
  "#B3B38F",
  "#E6E6A3",
  "#8F6B4D",
  "#E6C4A3",
];

const path = "catalog-tracker";
export const GET_MATCHES_REQUEST = `${path}/GET_MATCHES_REQUEST`;
export const GET_MATCHES_SUCCESS = `${path}/GET_MATCHES_SUCCESS`;
export const GET_MATCHES_CANCEL = `${path}/GET_MATCHES_CANCEL`;
export const GET_MATCHES_FAILURE = `${path}/GET_MATCHES_FAILURE`;

export const GET_MORE_MATCHES_REQUEST = `${path}/GET_MORE_MATCHES_REQUEST`;
export const GET_MORE_MATCHES_SUCCESS = `${path}/GET_MORE_MATCHES_SUCCESS`;
export const GET_MORE_MATCHES_CANCEL = `${path}/GET_MORE_MATCHES_CANCEL`;
export const GET_MORE_MATCHES_FAILURE = `${path}/GET_MORE_MATCHES_FAILURE`;

export const UPDATE_MAIN_ROOTDOMAIN = `${path}/UPDATE_MAIN_ROOTDOMAIN`;
