import { Component } from "react";
import utils from "../../../utils";
import { OKTA_DETAILS_URL } from "../../../config";
const { setDefaultAuthHeader, setToken } = utils.auth;


class OktaSetToken extends Component {
    async componentDidMount() {
      const { location, history } = this.props;
      const params = new URLSearchParams(location.search);
      const code = params.get("code");
      const state = params.get("state");
      const email = localStorage.getItem("oktaEmail");
      if (code && state) {
        try {
          const response = await fetch(
            `${OKTA_DETAILS_URL}?code=${code}&state=${state}&email=${email}&_redirectUri=${window.location.origin}/okta/setToken`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (!response.ok) {
            console.error("Failed to fetch token from backend");
            history.push("/login");
            return;
          }
  
          const accessToken = await response.text(); 
          if (accessToken) {
            setToken(accessToken);
            setDefaultAuthHeader();
            history.push("/");
          } else {
            history.push("/login");
          }
        } catch (error) {
          console.error("Error during token exchange:", error);
          history.push("/login");
        }
      } else {
        history.push("/login");
      }
    }
  
    render() {
      return null;
    }
  }
  
  export default OktaSetToken;
